<template>
  <main class="main" v-if="page">
    <article class="container">
      <BreadcrumbsComponent :title="page.title" v-if="page.title" />
      <div class="article">
        <div class="article__content article__content--full">
          <EditorJSComponent
            v-if="
              page &&
              page.page_texts &&
              page.page_texts.find((data) => data.name === 'description') &&
              page.page_texts.find((data) => data.name === 'description').text
            "
            :text="JSON.parse(page.page_texts.find((data) => data.name === 'description').text)"
          />
          <!--          <div class="article__info">-->
          <!--            <div class="article__info__published">Опубликовано:-->
          <!--              <time :time="page.date_publication | robotDate">{{ page.date_publication | formatDate }}</time>-->
          <!--            </div>-->
          <!--            <div class="article__info__share" v-if="page && page.title && page.description">-->
          <!--              <span>Поделиться:</span>-->
          <!--              <div class="article__info__share__buttons">-->
          <!--                <ShareNetwork :url="url" :description="page.description" :media="getImg(page)" network="vk" :title="page.title">-->
          <!--                  <VKIcon/>-->
          <!--                </ShareNetwork>-->
          <!--                <ShareNetwork :url="url" :description="page.description" network="facebook" :title="page.title">-->
          <!--                  <FBIcon/>-->
          <!--                </ShareNetwork>-->
          <!--                <ShareNetwork :url="url" network="twitter" :title="page.title">-->
          <!--                  <TWIcon/>-->
          <!--                </ShareNetwork>-->
          <!--                <ShareNetwork :url="url" :description="page.description" network="email" :title="page.title">-->
          <!--                  <ShareIcon/>-->
          <!--                </ShareNetwork>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </div>
    </article>
  </main>
  <NotFound v-else />
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";
import VKIcon from "components/svg/VK.vue";
import FBIcon from "components/svg/FB.vue";
import TWIcon from "components/svg/TW.vue";
import ShareIcon from "components/svg/Share.vue";
import NotFound from "@/views/PageNotFound.vue";
import { cityIn } from "lvovich";

export default {
  name: "Page",
  async asyncData({ store, res, route, link }) {
    store.state.alternateLink = link;
    await store.dispatch("GET_NAVIGATOR_PAGE");
    if (res) {
      if (store.state.navigator && !store.state.navigator.find((n) => n.link === route.fullPath.substr(1))) {
        res.status(404);
      }
    }
  },
  computed: {
    url() {
      return this.$store.state.api + this.$route.fullPath;
    },
    page() {
      let page;
      if (this.$store.state.navigator && this.$store.state.navigator.length) {
        const navigator = this.$store.state.navigator.find((n) => n.link === this.$route.fullPath.substr(1));
        if (navigator) {
          page = navigator.page;
          if (page) {
            page.page_texts = navigator.page.page_texts;
          }
        }
      }
      return page;
    },
  },
  methods: {
    getImg(page) {
      if (page.head_img && page.head_img.img && page.head_img.img.url) {
        return this.$store.state.api + page.head_img.img.url;
      }
    },
  },
  metaInfo() {
    let api = this.$store.state.api;
    let alternateLink = this.$store.state.alternateLink;
    let data = {};
    let city = this.$store.state.cities.find((c) => c.title_eng === this.$store.state.geoData.city);
    city = cityIn(city ? city.title : "Казань");
    if (this.$store.state.navigator && this.$store.state.navigator.length) {
      data = this.$store.state.navigator.find((n) => n.link === this.$route.fullPath.substr(1)) || {};
      data = data.page || {};
    }
    const title = (data.meta_title || data.title || "").replace(/{{ city }}/g, `в ${city}`);
    let metaFullPath = this.$route.fullPath;
    return {
      title,
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org",
            "@type": "WebPage",
            name: title,
            description: (data.meta_description || "").replace(/{{ city }}/g, `в ${city}`),
          },
        },
        {
          type: "application/ld+json",
          json: {
            "@context": "http://www.schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: "1",
                name: "Главная",
                item: api,
              },
              {
                "@type": "ListItem",
                position: "2",
                name: title,
                item: api + metaFullPath,
              },
            ],
          },
        },
      ],
      link: [
        { rel: "canonical", href: alternateLink },
        { rel: "alternate", hreflang: "x-default", href: alternateLink },
      ],
      meta: [
        {
          vmid: "keywords",
          name: "keywords",
          content: (data.meta_keywords || "").replace(/{{ city }}/g, `в ${city}`),
        },
        {
          vmid: "description",
          name: "description",
          content: (data.meta_description || "").replace(/{{ city }}/g, `в ${city}`),
        },
        {
          vmid: "og:title",
          name: "og:title",
          content: title,
        },
        {
          vmid: "og:image",
          name: "og:image",
          content: "",
        },
        {
          vmid: "og:description",
          name: "og:description",
          content: (data.meta_description || "").replace(/{{ city }}/g, `в ${city}`),
        },
      ],
    };
  },
  components: {
    NotFound,
    ShareIcon,
    TWIcon,
    FBIcon,
    VKIcon,
    EditorJSComponent,
    BreadcrumbsComponent,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/global/global.styl"
@import "~@/styles/parts/article.styl"
.p404 {
  margin-top margin
  margin-bottom margin
}
</style>
